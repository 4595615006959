import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { MatchConfigService } from './services/match-config/match-config.service';
import { first, map } from 'rxjs/operators';
import { routeParamsConfig } from './configs/route-params-config';

@Injectable()
export class InitConfigGuard implements CanActivate {
    constructor(
        private matchConfigService: MatchConfigService,
        private router: Router,
    ) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (state.url === '/') {
            return this.matchConfigService.createAnimationParametersViaPostMessage()
                .pipe(
                    first(),
                    map(status => {
                        if (!status) {
                            this.router.navigateByUrl(`/animation`, { skipLocationChange: true });
                        }

                        return status;
                    }),
                );
        }
        /**
         * deprecated method, will be disabled in nearest time */
        if (!this.matchConfigService.getCurrentConfig()[routeParamsConfig.matchID]) {
            const { paramMap, queryParamMap } = state.root.firstChild.firstChild;
            this.matchConfigService.getAnimationParametersViaURL(paramMap, queryParamMap);
        }

        return true;

        // TODO: remove deprecated method and add return false for disabling route
        // return false;
    }
}

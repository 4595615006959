import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { InitConfigGuard } from './init-config.guard';
import { MatchConfigService } from './services/match-config/match-config.service';
import { IframeCommunicationService } from './services/iframe-communication/iframe-communication.service';

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        BrowserAnimationsModule,
    ],
    providers: [
        MatchConfigService,
        IframeCommunicationService,
        InitConfigGuard,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
}

import { Injectable } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';

@Injectable()
export class IframeCommunicationService {
    private messagesFromParent$ = fromEvent(window, 'message');
    private parent = parent;

    public sendMessageToParent(msg: any): void {
        if (document.referrer) {
            this.parent.postMessage(msg, document.referrer);
        }
    }

    public getParentMessages$(): Observable<any> {
        return this.messagesFromParent$;
    }
}

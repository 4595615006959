import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { InitConfigGuard } from './init-config.guard';
import { routeParamsConfig } from './configs/route-params-config';

const routes: Routes = [
    {
        path: '',
        component: AppComponent,
        canActivate: [InitConfigGuard],
        children: [
            {
                path: `animation`,
                loadChildren: () => import('./match-visualization/match-visualization.module').then(m => m.MatchVisualizationModule),
            },
            // TODO: remove after ban routing by url with query params
            {
                path: `:${ routeParamsConfig.matchID }`,
                loadChildren: () => import('./match-visualization/match-visualization.module').then(m => m.MatchVisualizationModule),
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {
}

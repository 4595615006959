// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const preparedURL = window.location.hostname.replace('visualization.', '').replace('www.', '');
const preparedProtocol = window.location.protocol === 'https:' ? 's' : '';

export const environment = {
    production: false,
    white: false,
    mlSocketURL: `ws${ preparedProtocol }://animation.${ preparedURL }/animation`,
    b2bURL: 'https://visualization.dev.ml.bcua.io',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'timerFormatMinute',
})
export class TimerFormatMinutePipe implements PipeTransform {

    transform(value: number): string {
        if (value < 0 || isNaN(value) || (!value && value !== 0)) {
            return '';
        }
        let resultString = '';

        const seconds = value % 60;
        const minutes = (value - seconds) / 60;

        resultString += `${ (minutes > 9 ? '' : '0') }${ minutes }:`;

        resultString += seconds > 9 ? seconds : `0${ seconds }`;

        return resultString;
    }

}
